@use '../base/colors';

.iese-overlay-panel {
  background-color: colors.$base-1000;
  border: 1px solid colors.$base-600;
  border-radius: .5rem;
  box-shadow: .25rem .25rem 1rem 0 colors.$base-400;
  font-size: 16px;
  color: colors.$base-000;
}
