@use '../base/colors';
@use '../mixins/typography';
@use '../utilities/variables' as vars;

#iese-sidebar {
  background-color: colors.$secondary-lighter;
  border-right: 1px solid colors.$secondary-light;
  height: calc(100vh - #{vars.$toolbar-height});
  height: calc(100dvh - #{vars.$toolbar-height});
  width: vars.$sidebar-width;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  transition: width .3s ease-in-out;

  @media (max-width: vars.$layout-breakpoint-small) {
    position: absolute; // if media mobile
    z-index: 9;
  }

  .iese-sidebar-group-header, .iese-sidebar-group-footer {
    flex: 0 0 auto;
  }

  .iese-sidebar-group-header {
    padding-top: 16px;
  }

  .iese-sidebar-group-content {
    flex: 1 1 auto;
    overflow-y: auto;
    width: 100%;
    padding: 0 16px;
  }

  .iese-sidebar-group-header, .iese-sidebar-group-content, .iese-sidebar-group-footer {
    opacity: 1;
    overflow-x: hidden;
    transition: opacity .3s ease-in-out;
  }

  &.collapsed {
    width: 1px;
    overflow: hidden;

    .iese-sidebar-group-header, .iese-sidebar-group-content, .iese-sidebar-group-footer {
      opacity: 0;
    }
  }

  /* SUPPORT CENTER */
  .iese-sidebar-group-footer {
    padding: 16px;
    border-top: 1px solid colors.$secondary-light;

    .sidebar-support-center {
      h1 {
        @include typography.bold-text(16px, 24px, -0.8px);
      }

      .iese-sidebar-footer-buttons {
        display: flex;
        flex-direction: row;
        gap: 8px;
      }
    }
  }
}

#iese-sidebar-toggle {
  background-color: colors.$base-1000;
  position: absolute;
  z-index: 99;
  top: 16px;
  left: 16px;
  transition: left .3s ease-in-out;
}
