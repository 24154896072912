@use '../base/colors';
@use '../mixins/typography';

.p-dialog {
  .p-dialog-content {
    padding: .5rem;
    box-shadow: 0 .5rem .5rem 0 rgba(0, 0, 0, 0.1) inset, 0 -.5rem .5rem 0 rgba(0, 0, 0, 0.1) inset;
    background-color: colors.$base-800;
  }
}

.p-confirm-dialog, .clean-dialog {
  .p-dialog-content {
    padding: .5rem 1.5rem;
    box-shadow: none;
    background-color: colors.$base-1000;
  }
}

.help-dialog {
  .p-dialog-content {
    padding: .5rem 1.5rem;
    box-shadow: none;
    background-color: colors.$base-1000;
  }
}

.form-dialog {
  .p-dialog-header {
    @include typography.bold-text(20px, 24px, -0.4px);
    padding: 1rem 1.5rem;
  }

  .p-dialog-content {
    padding: .5rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.p-dialog-footer {
  @include typography.normal-text(14px);
  padding: 1rem;

  .iese-button {
    @include typography.normal-text(14px);
  }
}

.dialog-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 1em;
}
