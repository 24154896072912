.toolbar-user-profile__container {

  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding-right: 16px;
  cursor: pointer;

  .username {
    font-size: 14px;
  }

  .avatar {
    svg {
      height: 40px;
      width: 40px;
    }
  }

}
