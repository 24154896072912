@use '../base/colors';

.chat-message-notification {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  border: 2px solid colors.$secondary-light;
  border-radius: 8px;
  padding: 8px;

  i {
    font-size: 1.25rem;
  }

  &.error {
    border: 2px solid transparentize(colors.$support-alert, .5);
    background-color: transparentize(colors.$support-alert, .9);
    color: darken(colors.$support-alert, 10%);
  }

  &.info {
    border: 2px solid transparentize(colors.$secondary-dark, .5);
    background-color: transparentize(colors.$secondary-dark, .9);
    color: darken(colors.$secondary-dark, 10%);
  }
}
