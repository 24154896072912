/*
 * MAIN COLORS
 */
$primary: #FF0000;
$primary-light: #FF4747;
$primary-lighter: #FFADAD;

$secondary-dark: #728D86;
$secondary-darker: #967D69;
$secondary-light: #E8E2DE;
$secondary-lighter: #F7F5F3;

$base-000: #212121;
$base-200: #878787;
$base-400: #C9C9C9;
$base-600: #E4E4E4;
$base-800: #F2F4F7;
$base-1000: #FFFFFF;

$support-success: #02C875;
$support-error: #D60505;
$support-yellow: #FFD337;
$support-alert: #ED9B0C;
