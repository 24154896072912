@use '../base/colors';
@use '../mixins/typography';

$badge-dot-size: .75em;

button {
  background: none;
  border: none;
  cursor: pointer;
}

a {
  cursor: pointer;
  color: colors.$primary;
  font-weight: bold;
  text-decoration: none;
  line-break: anywhere;

  &:hover {
    color: colors.$primary-light;
  }

  .ext-link {
    font-size: xx-small;
  }
}

a.text-link {

}

.iese-button {
  cursor: pointer;
  background-color: colors.$base-1000;
  color: colors.$base-000;
  padding: .75em 1em;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;

  &:hover {
    background-color: colors.$base-800;
  }

  &:active {
    background-color: colors.$base-600;
    color: colors.$base-200;
  }

  &.disabled {
    background-color: colors.$base-800;
    color: colors.$base-400;
    cursor: default;
    pointer-events: none;
  }

  &.primary {
    background-color: colors.$primary;
    color: colors.$secondary-lighter;

    &:hover {
      background-color: colors.$primary-light;
      color: colors.$secondary-lighter;
    }

    &:active {
      background-color: colors.$primary-lighter;
    }

    &.disabled {
      background-color: colors.$primary-lighter;
      cursor: default;
    }
  }
}

.iese-rounded-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  font-size: 1.1rem;
  cursor: pointer;

  &:hover {
    background-color: colors.$secondary-lighter;
  }

  &:active {
    background-color: colors.$secondary-light;
  }

  &.disabled {
    background-color: colors.$secondary-light;
    cursor: default;
  }

  &.primary {
    background-color: colors.$primary;
    color: colors.$secondary-lighter;

    &:hover {
      background-color: colors.$primary-light;
      color: colors.$secondary-lighter;
    }

    &:active {
      background-color: colors.$primary-lighter;
    }
  }

  &.secondary {
    background-color: colors.$base-400;
    color: colors.$base-000;

    &:hover {
      background-color: colors.$base-600;
    }
  }

  &.outlined {
    background-color: colors.$base-1000;
    border: 2px solid colors.$primary;
    color: colors.$primary;

    i {
      font-weight: bolder;
    }

    &:hover {
      background-color: colors.$primary;

      i {
        color: colors.$base-1000;
      }
    }
  }

  &.small {
    width: 16px;
    height: 16px;
    border-width: 1.5px;

    .pi {
      font-size: 8px;
      font-weight: bold;
    }
  }

  &.p-overlay-badge {
    .p-badge {
      background-color: colors.$primary;
      transform: translate(25%, -25%);

      &.p-badge-dot {
        width: $badge-dot-size;
        min-width: $badge-dot-size;
        height: $badge-dot-size;
      }
    }
  }
}

.access-link {
  @include typography.bold-text();
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    opacity: .5;
  }
}

i.primary {
  color: colors.$primary;
}
