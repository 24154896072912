@use '../base/colors';
@use '../mixins/typography';

.chat-history-list {
  display: flex;
  flex-direction: column;

  .chat-history-item {
    @extend .iese-button;

    background-color: colors.$base-1000;
    color: colors.$base-000;
    width: 100%;
    justify-content: flex-start;
    padding: .25rem .5rem;

    &.chat-history-item-renaming {
      background-color: #FFFFFF;
      color: #212121;
      padding: 0.75em 1em;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-size: 14px;
    }

    .chat-history-item-label, .chat-history-item-input {
      @include typography.normal-text(14px, 18px, -0.8px);
      flex: 1 1 100%;
    }

    .chat-history-item-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .chat-history-item-input {
      // TODO extraer
      background-color: white;
      border: 2px solid colors.$secondary-light;
      border-radius: 8px;
      width: 100%;
      flex: 1 1 100%;
    }

    i {
      font-size: .8rem;
    }

    &:hover {
      background-color: colors.$secondary-light;
      color: colors.$base-000;
    }

    &.active {
      border-left: 4px solid colors.$primary;
      background-color: colors.$base-1000;
    }

    .chat-history-item-actions {
      display: flex;
      flex-direction: row;
      gap: 4px;
    }
  }

  .chat-history-subtitle {
    @include typography.bold-text();
    margin-block-start: 1em;
    margin-block-end: .5em;
  }

  .chat-history-sublist {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
