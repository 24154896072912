@use '../base/colors';
@use '../mixins/typography';

.p-confirm-dialog {
  .p-dialog-header {
    color: colors.$base-200;
    padding: 8px 8px 8px 16px;

    .p-dialog-header-close {
      color: colors.$base-000;
    }
  }

  .p-dialog-content {
    color: colors.$base-000;
    padding: 16px;

    i {
      font-size: 1.5rem;
    }
  }

  .p-dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .p-confirm-dialog-reject, .p-confirm-dialog-accept {
      @include typography.bold-text();
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    .p-confirm-dialog-reject {
      color: colors.$base-000;

      &:hover {
        color: colors.$base-200;
      }
    }

    .p-confirm-dialog-accept {
      background-color: colors.$primary;
      color: colors.$base-1000;

      &:hover {
        background-color: colors.$primary-light;
        color: colors.$base-1000;
      }
    }
  }
}
