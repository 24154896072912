@use '../base/colors';
@use '../utilities/variables' as vars;

.main-chat-box-container {
  padding: 0;
  width: 100%;
  border-top: 1px solid colors.$secondary-light;
  background-color: colors.$secondary-lighter;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .main-chat-box-prompt {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    justify-content: center;
    padding: 0;

    .main-chat-box-input {
      background-color: white;
      border: 2px solid colors.$secondary-light;
      border-radius: 8px;
      resize: none;
      outline: 0;
      flex: 1 1 100%;
      max-width: 80%;
    }

    .send {
      @apply .iese-rounded-button;
    }

    .p-element.p-icon-wrapper svg {
      display: none;
    }
  }

  .chat-box-message-color {
    color: #9c9b9b;
  }

  .fromIESEKnowledgeWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0.5rem;
  }

  .fromIESEKnowledgeLabel {
    display: contents;
    cursor: pointer;
  }

  @media (max-width: vars.$layout-breakpoint-small) {
    .fromIESEKnowledgeLabel {
      display: none;
    }
  }
}
