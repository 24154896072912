
.cdk-overlay-container:not(:empty) {
  position: fixed;
  z-index: 1000;
  background-color: rgba($color: #000000, $alpha: 0.2);
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  // pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.mat-dialog-iese {
  padding: 10px 18px 16px;
  outline: none;
  background: #FFFFFF;

  .header {
    font-size: 18px;
    font-weight: 700;
    color: red;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .sideNote {
      color: #b1bcc7;
      font-size: 12px;
    }
  }
}

.buttonsSkip {
  display: flex;
  gap: 12px;
  flex-direction: row-reverse;
  padding-bottom: 6px;

  .iese-button-skip {
    background-color: lightgray;
    color: black;

    &:hover {
      background-color: #b1bcc7;
    }
  }
}

div.mat-mdc-menu-panel.bdc-walk-popup .title .header, div.mat-menu-panel.bdc-walk-popup .title .header {
  color: red !important;
}

div.mat-mdc-menu-panel.bdc-walk-popup .buttons button, div.mat-menu-panel.bdc-walk-popup .buttons button {
  background-color: #FF0000;
  color: #F7F5F3 !important;
  padding: 0.75em 1em;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  border: 0;

  &:hover {
    background-color: #FF4747;
    color: #F7F5F3;
  }
}
