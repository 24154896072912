@use '../base/colors';
@use '../mixins/typography';
@use '../utilities/variables' as vars;

.chat-messages-container {

  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  align-items: flex-start;
  justify-content: flex-start;

  .chat-message-row {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px;
    width: 85%;
    border-radius: 8px;

    &.role-system {
      display: none;
      align-self: center;
      background-color: colors.$secondary-light;
      border: 2px solid colors.$secondary-light;
      color: colors.$secondary-darker;
    }

    &.role-user {
      align-self: flex-end;
      background-color: colors.$secondary-lighter;
      border: 2px solid colors.$secondary-light;

      .chat-message-container {
        .chat-message {
          overflow-wrap: anywhere;
        }
      }
    }

    &.role-assistant {
      .chat-message-avatar {
        margin-top: 4px;
      }
    }

    .chat-message-container {
      .chat-message {
        padding: 0;

        p {
          margin: 0 0 8px;
        }

        pre {
          text-wrap: wrap;
        }

        /* Default Table Style */
        table {
          color: #333;
          background: white;
          font-size: 12pt;
          border-collapse: collapse;
          margin: 0 0 8px;
          border: 2px solid #E8E2DE;
          border-radius: 8px;
          /* Zebra Table Style */
          // tbody tr:nth-of-type(even) {
          //   background: rgba(0,0,0,.05);
          // }
          // tbody tr:nth-of-type(odd) {
          //   background: rgba(255,255,255,.05);
          // }
        }

        table thead th,
        table tfoot th {
          color: #777;
          background: rgba(0, 0, 0, .1);
        }

        table caption {
          padding: .5em;
        }

        table th,
        table td {
          padding: .5em;
          border: 1px solid lightgrey;
        }

      }

      .chat-message-actions {
        display: flex;
        flex-direction: row;

      }

      .chat-message-citations {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: flex-start;
        align-items: flex-start;

        button {
          display: flex;
          flex-direction: row;
          gap: 8px;
          border: 1px solid colors.$secondary-light;
          background-color: colors.$secondary-lighter;
          border-radius: 8px;
          padding: .5em 1em;

          span {
            @include typography.normal-text(12px, 14px, -0.4px);
          }

          &:hover {
            background-color: colors.$primary;
            color: colors.$base-1000;
          }
        }
      }
    }
  }

}
