*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-size: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
}

button, a, input, textarea, select {
  border: none;
  outline: none;
  box-shadow: none;
}
