@use '../base/colors';

/*
.iese-checkbox {
  border: 3px double red;
}
*/

.iese-checkbox .p-checkbox,
.iese-checkbox .p-checkbox-box {
  width: 18px;
  height: 18px;
  box-shadow: none;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: colors.$primary;
}

.iese-checkbox .p-checkbox-box.p-focus {
  border-color: colors.$primary;
}

.iese-checkbox .p-checkbox-box.p-highlight {
  background-color: colors.$primary;
  border-color: colors.$primary;

  .p-element.p-icon-wrapper svg {
    display: none;
  }

  .p-element.p-icon-wrapper::before {
    content: '\e909';
    font-family: 'primeicons', serif !important;
    font-size: 10px;
    color: white;
  }
}
