@use '../mixins/typography';
@import '../../fonts/graphik-lgc';

* {
  font-family: 'Graphik LCG', serif;
}

strong, b {
  font-weight: 600;
}

em {
  font-style: italic;
}

small {
  @include typography.normal-text(13px, 18px, -0.8px);

  a {
    @include typography.bold-text(13px, 18px, -0.6px);
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-block: 0;
  margin-block-end: .5rem;
}

h1 {
  @include typography.bold-text(36px, 48px);
}

h2 {
  @include typography.bold-text(32px, 36px);
}

h3 {
  @include typography.bold-text(28px, 32px);
}

h4 {
  @include typography.bold-text(24px, 28px);
}

h5 {
  @include typography.bold-text(20px, 20px);
}

h6 {
  @include typography.bold-text(16px, 20px);
}
