@use '../base/colors';
@use '../utilities/variables' as vars;

#iese-toolbar {
  width: 100%;
  //position: fixed;
  top: 0;
  left: 0;

  .p-toolbar {
    border: 1px solid colors.$secondary-light;
    border-bottom-width: 2px;
    border-radius: 0;
    background-color: colors.$base-1000;
    max-width: vars.$layout-max-width;
    height: vars.$toolbar-height;
    margin: 0 auto;
    padding: 0;
  }

  .iese-toolbar-group-start {
    width: vars.$sidebar-width;
    padding-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: vars.$layout-breakpoint-small) {
    .iese-toolbar-group-start {
      order: 1;
      flex-basis: 50%;
      box-sizing: border-box;
      width: auto;
    }
    .iese-toolbar-group-center {
      order: 3;
      flex-basis: 100%;
    }
    .iese-toolbar-group-end {
      order: 2;
      flex-basis: 47%;
      /* box-sizing: border-box; */
      display: flex;
      justify-content: flex-end;
    }
  }
}
