@use '../base/colors';
@use '../mixins/typography';

.citation-header {

}

.citation-metadata-list {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;

  .citation-metadata-item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;

    strong, span, a {
      font-size: 13px;
    }
  }
}

.dialog-content {
  a {
    line-break: anywhere;
  }
}
