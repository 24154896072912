@mixin normal-text($fontSize: 16px, $lineHeight: 24px, $letterSpacing: -0.4px) {
  font-style: normal;
  font-weight: 400;
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin bold-text($fontSize: 16px, $lineHeight: 24px, $letterSpacing: -0.4px) {
  font-style: normal;
  font-weight: 600;
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin italic-text($fontSize: 16px, $lineHeight: 24px, $letterSpacing: -0.4px) {
  font-style: italic;
  font-weight: 400;
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}
