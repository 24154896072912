@use '../base/colors';
@use '../mixins/typography';

.iese-toast {

  .p-toast-message {
    background-color: transparentize(colors.$base-1000, .1);
    backdrop-filter: blur(2px);
    color: colors.$base-000;

    .p-toast-summary {
      @include typography.bold-text(16px, 20px, -0.2px);
    }
    .p-toast-detail {
      @include typography.normal-text(14px, 18px, 0.2px);
    }
  }
}
