@use '../base/colors';
@use '../mixins/typography';

.p-tooltip {
  background-color: transparentize(colors.$base-000, .2);
  backdrop-filter: blur(2px);
  border-radius: 8px;
}

.p-tooltip-text {
  @include typography.normal-text(12px, 16px, -0.4px);
  background: none;
  padding: .5em 1em;
  color: colors.$base-1000;
}
