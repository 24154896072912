@use '../base/colors';
@use '../mixins/typography';
@use '../utilities/variables' as vars;

$overlay-filter: drop-shadow(1px 1px 3px colors.$base-000);

.file-upload-button {
  input {
    opacity: 1;
    position: absolute;
    z-index: -1;
    width: 1px;
    height: 1px;
    top: 0;
    left: 0;
  }

  label {
    cursor: pointer;
  }
}

.file-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 1rem;
  background-color: colors.$secondary-light;
  border: 1px solid darken(colors.$secondary-light, 10);
  border-radius: 1.25rem;
  min-height: 3rem;
  padding: .25rem;
  overflow-x: auto;

  .file-chip, .chat-file-chip {
    font-family: 'Graphik LCG', sans-serif;
    display: flex;
    flex-direction: row;
    background-color: colors.$base-1000;
    border: 1px solid colors.$base-400;
    border-radius: 16px;
    color: colors.$base-000;
    gap: .25rem;
    padding: .25rem .5rem .25rem .25rem;
    cursor: default;

    &-filename {
      padding: 0 .25rem;
      font-size: 12px;
      max-width: 128px;
      font-family: 'Graphik LCG', sans-serif;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.chat-file-chip {
  opacity: .8;
  filter: saturate(50%);
}

.file-icon-small {
  width: 24px !important;
  height: 24px !important;
}

.file-icon-medium {
  width: 64px !important;
  height: 64px !important;
}

.drag-drop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  z-index: 1000;
  transition: opacity .1s ease-in-out;

  .drag-drop-overlay-content {
    background-color: transparentize(colors.$secondary-dark, .25);
    backdrop-filter: blur(1px);
    display: flex;
    border-radius: 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 100%;
    pointer-events: none;

    svg {
      width: 64px;
      height: 64px;
      filter: $overlay-filter;
    }

    span {
      @include typography.bold-text(20px, 28px, 0.8px);
      color: colors.$base-1000;
      filter: $overlay-filter;
    }
  }
}
