@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Medium Italic'), local('GraphikLCG-MediumItalic'),
  url('GraphikLCG-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Super Italic'), local('GraphikLCG-SuperItalic'),
  url('GraphikLCG-SuperItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Semibold Italic'), local('GraphikLCG-SemiboldItalic'),
  url('GraphikLCG-SemiboldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Black'), local('GraphikLCG-Black'),
  url('GraphikLCG-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Super'), local('GraphikLCG-Super'),
  url('GraphikLCG-Super.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Thin'), local('GraphikLCG-Thin'),
  url('GraphikLCG-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Extralight Italic'), local('GraphikLCG-ExtralightItalic'),
  url('GraphikLCG-ExtralightItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Regular'), local('GraphikLCG-Regular'),
  url('GraphikLCG-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Bold Italic'), local('GraphikLCG-BoldItalic'),
  url('GraphikLCG-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Light Italic'), local('GraphikLCG-LightItalic'),
  url('GraphikLCG-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Black Italic'), local('GraphikLCG-BlackItalic'),
  url('GraphikLCG-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Medium'), local('GraphikLCG-Medium'),
  url('GraphikLCG-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Light'), local('GraphikLCG-Light'),
  url('GraphikLCG-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Semibold'), local('GraphikLCG-Semibold'),
  url('GraphikLCG-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Bold'), local('GraphikLCG-Bold'),
  url('GraphikLCG-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Extralight'), local('GraphikLCG-Extralight'),
  url('GraphikLCG-Extralight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Thin Italic'), local('GraphikLCG-ThinItalic'),
  url('GraphikLCG-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG Regular Italic'), local('GraphikLCG-RegularItalic'),
  url('GraphikLCG-RegularItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}
