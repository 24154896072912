@use '../base/colors';
@use '../mixins/typography';

p-dropdown {
  width: 256px;

  .p-dropdown {
    @include typography.normal-text(14px, 18px, -0.4px);
    border: 2px solid colors.$base-600;
    outline: none;
    box-shadow: none;
    border-radius: 6px;

    &:focus-within {
      border-color: colors.$primary;
    }

    .p-dropdown-panel {
      border: 2px solid colors.$base-600;
      border-radius: 6px;

      .p-dropdown-items-wrapper {
        .p-dropdown-items {
          padding: 0;

          .p-dropdown-item {
            padding: .5rem;
            background-color: colors.$base-1000;

            &:hover {
              background-color: colors.$base-600;
            }

            &.p-highlight {
              background-color: colors.$base-800;
              color: colors.$primary;

              &:hover {
                background-color: colors.$base-600;
              }
            }
          }
        }
      }
    }
  }
}
