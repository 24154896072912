@use '../base/colors';
@use '../utilities/variables' as vars;

#iese-layout {
  background-color: colors.$base-1000;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh);
  height: calc(100dvh);

  &-container {
    background-color: colors.$base-1000;
    border: 1px solid colors.$base-600;
    border-top: none;
    width: 100%;
    max-width: vars.$layout-max-width;
    overflow: hidden;
    flex: 1 1 auto;
  }

  &-main-content {
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: colors.$base-1000;
    width: 100%;
    min-height: calc(100vh - #{vars.$toolbar-height});
    min-height: calc(100dvh - #{vars.$toolbar-height});
    overflow-x: clip;
  }

}
